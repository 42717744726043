@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  height: 232px;
  background: linear-gradient(
    90deg,
    $color-blue-2 -47.96%,
    $color-blue-3 23.69%
  );
}

.inner {
  margin: auto;
}

.title {
  text-align: center;
  color: $color-white;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 30px;
}

@media (max-width: $sm) {
  .title {
    font-size: 24px;
  }
}
@import "@causevest/ui-kit/variables";

.wrapper {
  background: $color-white;
  gap: 45px;
}

.titleLine {
  padding: 20px 0;
  background: $color-bg-blue;

  h2 {
    text-align: center;
  }
}

.title {
  color: $color-black-6;
  font-size: 36px;
  font-weight: 300;
}

.subtitle {
  margin-top: 16px;
  color: $color-grey-2;
}

.campaigns {
  margin-top: 20px;

  &__wrapper {
    margin-top: 40px;
    padding-bottom: 20px;

    &._creators {
      margin-bottom: 40px;
    }
  }

  &__slide {
    max-width: 265px;
    margin-bottom: 1px;
  }
}

.trusted {
  gap: 35px;

  &__title {
    font-size: 18px;
    font-weight: 300;
  }

  &__img {
    width: 100px;
    height: 67px;
    border: 1px solid $color-light-grey;
    border-radius: 8px;
    display: flex;

    > img {
      margin: auto;
    }
  }
}

@media (max-width: $md) {
  .campaigns {
    &__wrapper {
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }
}

@media (max-width: $sm) {
  .title {
    font-size: 24px;
  }

  .subtitle {
    margin-top: 14px;
    font-size: 14px;
    color: $color-grey-2;
  }
}
